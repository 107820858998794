@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #F1F1F1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(133, 112, 112);
}

header {
  box-shadow: 0 0 6px 0 #5f6368ff !important;
}


.login-form-logo {
  z-index: 3;
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  width: 510px;
  height: 100px;
  margin: auto;
  background: #F5FAFF 0% 0% no-repeat padding-box;
  padding: 20px 0px;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  box-shadow: 5px 10px 10px #00000029;
}

.login-form {
  transform-style: preserve-3d;
  background-color: white;
  z-index: 2;
  position: fixed;
  top: 160px;
  left: 0;
  right: 0;
  width: 470px;
  margin: auto;
}

.login-form::before {
  content: "";
  position: absolute;
  top: 24px;
  left: -14px;
  background: #F5FAFF 0% 0% no-repeat padding-box;
  height: 30px;
  width: 30px;
  transform: translateZ(-10px) rotate(45deg)
}

.login-form::after {
  content: "";
  position: absolute;
  top: 24px;
  right: -14px;
  background: #F5FAFF 0% 0% no-repeat padding-box;
  height: 30px;
  width: 30px;
  transform: translateZ(-10px) rotate(45deg);
}

.login-form-bottom {
  width: 100%;
  height: 48px;
  background: #F5FAFF 0% 0% no-repeat padding-box;
  border-top: 1px solid #EBEBEB;
  opacity: 1;
  border-radius: 50% 50% 0 0;
}

button {
  box-shadow: none !important;
}

.ant-upload.ant-upload-select {
  width: 100%;
}


/* Active Link */
#main-grid-link {
  width: 200px !important;
}

#main-grid-link a {
  display: block;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  color: black;
  -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  padding: .5rem;
  width: 100%;

}

#main-grid-link a.active {
  background: #000 !important;
  color: white;
}

#main-grid-sub-link {
  width: 180px;
  height: 150px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
  -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  background: white;
}

#main-grid-sub-link a.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.clsl{
  padding: 10px;
  background: white;
  -moz-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
  display: flex;
}

.ant-modal-fullscreen {
  width: 100vw !important;
  height: 100vh;
  top: 0px !important;
  max-width: none !important;
  margin: 0 !important;
}

.ant-modal-fullscreen .ant-modal-body {
  height: calc(100vh - 110px) !important;
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ant-modal-fullscreen .ant-modal-content {
  height: 100vh;
  width: 100vw;
}

.ant-form legend {
  display: block;
  width: fit-content;
  margin-bottom: 0px;
  padding: 6px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 0px;
}

.ant-form fieldset {
  border: 1px solid #d9d9d9;
}

.tox.tox-silver-sink.tox-tinymce-aux .tox-notifications-container {
  display: none !important;
  top: 0px !important;
}


/* LEFT MENU CSS */
.ant-menu-item {
  display: flex !important;
  align-items: center !important;
  padding: 1.8rem;
  font-weight: 700;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.ant-menu-item .anticon.ant-menu-item-icon {
  font-size: 20px;
}

.ant-layout-header.css-dev-only-do-not-override-zwa6fk, header {
  box-shadow: none !important;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.css-dev-only-do-not-override-zwa6fk {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  margin: 20px;
  z-index: 180;
}

.ant-layout-sider-children {
  background-color: #F5F5FC !important;
  z-index: 200;
}

:where(.css-dev-only-do-not-override-zwa6fk).ant-menu-light .ant-menu-item-selected {
  background-color: #EFEFF6;
  position: relative;
}

:where(.css-dev-only-do-not-override-zwa6fk).ant-menu-light .ant-menu-item-selected::after {
  content: "";
  position: absolute;
  right: 0;
  background: #000;
  height: 100%;
  width: 4px;
}

.ant-upload-wrapper.Company-logo.css-dev-only-do-not-override-13ryqzd.ant-upload-picture-card-wrapper {
  border: 1px solid green;
  /* width: 100% !important; */
  height: 100% !important;
  /* display: block !important; */
}

.ant-upload-wrapper.Company-logo.css-dev-only-do-not-override-13ryqzd.ant-upload-picture-card-wrapper>.ant-upload.ant-upload-select {
  width: 100%;
  height: 500px;
}
.ant-upload-wrapper.Company-logo.css-dev-only-do-not-override-13ryqzd.ant-upload-picture-card-wrapper>.ant-upload-list-item-container {
  width: 100%;
  height: 500px;
}

.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.css-dev-only-do-not-override-zwa6fk{
  margin-left: 0;
}

.ant-input-affix-wrapper.searchInput.css-dev-only-do-not-override-zwa6fk.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-zwa6fk{
  outline: none;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.1) 0px 2px 4px;
}

.first-column-bg-like-header table tbody tr td:first-child{
  background-color: #F0F0F0!important;
}
.table-td-without-padding table tbody tr td{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

.ant-color-picker-color-block{
  width: 100%;
}


.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.seperated-rows table {
  border-collapse: separate!important;
  border-spacing: 0px 10px!important;
}

.seperated-rows table th, .seperated-rows table td {
  border-top: 1px solid rgb(112, 112, 112)!important;
  border-left: 1px solid rgb(112, 112, 112)!important;
}
.seperated-rows table .ant-table-expanded-row td{
  border: none!important;
}

.seperated-rows table th:last-child, .seperated-rows table td:last-child {
  border-right: 1px solid rgb(112, 112, 112)!important;
}
.text-right {
  text-align: right!important;
}
.bg-red{
  background-color: red!important;
}